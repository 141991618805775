import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { gql } from 'graphql-request';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Bold, Heading, Paragraph } from '@/components/dom/text-elements';
import { LoginForm } from '@/components/forms/login-form';
import { OTPForm } from '@/components/forms/otp-form';
import { SignupForm } from '@/components/forms/signup-form';
import Button from '@/components/global/button';
import HighlightedText from '@/components/ui/HighlightedText';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { API_ENDPOINT, GOOGLE_OAUTH_CLIENT_ID } from '@/lib/constants';
import { GQL_CLIENT } from '@/lib/graphql';
import { CURRENT_USER_QUERY, CurrentUserType } from '@/queries/current-user';
import { type BaseTrackingProperties, type ErrorTrackingProperties } from '@/types/tracking';

import { useGlobalState } from '../global/global-state';
import { SignUpSource, SignUpSourceCopyMap } from '../providers/LoggedInGate';

const RESEND_OTP_QUERY = gql`
    mutation ResendLoginAttempt($loginAttemptId: ID!) {
        resendLoginAttempt(input: { loginAttemptId: $loginAttemptId }) {
            __typename
            ... on SendLoginAttemptSuccess {
                loginAttemptId
            }
            ... on Error {
                message
            }
        }
    }
`;

export enum OpenWithTypes {
    LOGIN = 'log-in',
    SIGNUP = 'sign-up',
    OTP = 'otp',
}

interface LoginSignupFormProps {
    openWith?: OpenWithTypes;
    signUpSource?: SignUpSource;
    onSuccess?: (user: CurrentUserType) => void;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
    allowSocialLogin: boolean;
}

interface LoginContentProps {
    onSuccess: (loginAttemptId: string, email: string) => void;
    showSignUpHandler: () => void;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

const LoginContent: React.FC<LoginContentProps> = ({
    onSuccess,
    showSignUpHandler,
    trackingProperties = {} as BaseTrackingProperties,
}) => {
    const { categories, components, eventTypes } = usePosthogTracking();

    return (
        <div className="flex flex-col justify-center gap-y-6 animate-fadeIn">
            <Paragraph className="mb-4 text-center">
                Don&apos;t have an account?&nbsp;
                <Button
                    type="action"
                    color="transparent"
                    onClick={showSignUpHandler}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                    tracking={{
                        eventType: eventTypes.SHOW_SIGNUP_FORM,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.LOGIN_SIGNUP_MODAL,
                            label: 'Create one',
                        },
                    }}
                >
                    Create one
                </Button>{' '}
                in less than two mins
            </Paragraph>

            <LoginForm
                onSuccess={onSuccess}
                className="w-full"
                trackingProperties={trackingProperties}
            />
        </div>
    );
};
interface SignupContentProps {
    disableLogin?: boolean;
    signUpSource?: SignUpSource;
    onSuccess: (loginAttemptId: string, email: string) => void;
    showLoginHandler: () => void;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

const SignupContent: React.FC<SignupContentProps> = ({
    disableLogin,
    signUpSource,
    onSuccess,
    showLoginHandler,
    trackingProperties = {} as BaseTrackingProperties,
}) => {
    const { categories, components, eventTypes } = usePosthogTracking();

    return (
        <div className="flex flex-col justify-center gap-y-6 animate-fadeIn">
            {signUpSource && SignUpSourceCopyMap[signUpSource] && (
                <Paragraph className="font-brand-md mb-2 text-center text-analyst-darker-gray">
                    {SignUpSourceCopyMap[signUpSource]}
                </Paragraph>
            )}

            {!disableLogin && (
                <Paragraph className="text-center">
                    Already have an account?{' '}
                    <Button
                        type="action"
                        color="transparent"
                        onClick={showLoginHandler}
                        className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                        tracking={{
                            eventType: eventTypes.SHOW_LOGIN_FORM,
                            trackingProperties: {
                                ...trackingProperties,
                                category: categories.LOGIN_SIGNUP,
                                component: components.LOGIN_SIGNUP_MODAL,
                                label: 'Log in',
                            },
                        }}
                    >
                        Log in
                    </Button>
                </Paragraph>
            )}

            <SignupForm
                onSuccess={onSuccess}
                trackingProperties={trackingProperties}
            />
        </div>
    );
};

interface OTPContentProps {
    email: string;
    loginAttemptId: string;
    onLoginSuccess?: (user: CurrentUserType) => void;
    submitButtonCopy: string;
    showLoginHandler: () => void;
    actionType?: OpenWithTypes;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

const OTPContent: React.FC<OTPContentProps> = ({
    actionType,
    email,
    loginAttemptId,
    onLoginSuccess,
    submitButtonCopy,
    showLoginHandler,
    trackingProperties = {} as BaseTrackingProperties,
}) => {
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const resendOtp = async () => {
        const variables = { loginAttemptId };

        trackManualEvent({
            eventType: eventTypes.RESEND_OTP,
            trackingProperties: {
                category: categories.LOGIN_SIGNUP,
                email,
                loginAttemptId,
            },
        });

        const data: {
            resendLoginAttempt:
                | { __typename: 'SendLoginAttemptSuccess'; loginAttemptId: string }
                | { __typename: 'Error'; message: string };
        } = await GQL_CLIENT.request(RESEND_OTP_QUERY, variables);
        const result = data.resendLoginAttempt;
        const success = result.__typename === 'SendLoginAttemptSuccess';

        if (success) {
            trackManualEvent({
                eventType: eventTypes.RESEND_OTP_SUCCESS,
                trackingProperties: {
                    category: categories.LOGIN_SIGNUP,
                    email,
                    loginAttemptId,
                },
            });
            toast.success('Your one-time passcode was resent!', {
                style: {
                    zIndex: 10000,
                },
            });
        } else {
            trackManualEvent({
                eventType: eventTypes.RESEND_OTP_ERROR,
                trackingProperties: {
                    category: categories.LOGIN_SIGNUP,
                    email,
                    loginAttemptId,
                },
            });
            toast.error(result.message);
        }
    };

    return (
        <div className="flex flex-col justify-center gap-y-4 animate-fadeIn">
            <Heading importance={5}>Your one-time passcode was sent.</Heading>
            <Paragraph className="mb-2">
                Check your email (<Bold>{email.toLowerCase()}</Bold>) for your one-time passcode. The passcode expires
                in 15 minutes. If you didn&apos;t receive it, verify your email address and check your spam folder.
            </Paragraph>
            <Paragraph className="mb-2">
                Problems?{' '}
                <Button
                    type="action"
                    color="transparent"
                    onClick={resendOtp}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                    tracking={{
                        eventType: eventTypes.RESEND_OTP,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.LOGIN_SIGNUP_MODAL,
                            label: 'Resend passcode',
                        },
                    }}
                >
                    Resend passcode
                </Button>
                &nbsp;or&nbsp;
                <Button
                    type="action"
                    color="transparent"
                    onClick={showLoginHandler}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                    tracking={{
                        eventType: eventTypes.SHOW_LOGIN_FORM,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.LOGIN_SIGNUP_MODAL,
                            label: 'Re-enter your email',
                        },
                    }}
                >
                    Re-enter your email
                </Button>
                .
            </Paragraph>

            {actionType === OpenWithTypes.SIGNUP && (
                <Paragraph>
                    Already have an account?{' '}
                    <Button
                        type="action"
                        color="transparent"
                        onClick={showLoginHandler}
                        className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                        tracking={{
                            eventType: eventTypes.SHOW_LOGIN_FORM,
                            trackingProperties: {
                                ...trackingProperties,
                                category: categories.LOGIN_SIGNUP,
                                component: components.LOGIN_SIGNUP_MODAL,
                                label: 'Log in',
                            },
                        }}
                    >
                        Log in
                    </Button>
                </Paragraph>
            )}

            <OTPForm
                loginAttemptId={loginAttemptId}
                onLoginSuccess={onLoginSuccess}
                submitButtonCopy={submitButtonCopy}
                trackingProperties={trackingProperties}
            />
        </div>
    );
};

const FORM_TITLE_MAP: {
    [key in OpenWithTypes]?: {
        title?: React.ReactNode;
    };
} = {
    [OpenWithTypes.LOGIN]: {
        title: 'Welcome back! Log into your account below.',
    },
    [OpenWithTypes.SIGNUP]: {
        title: (
            <>
                Sign up and keep exploring for <HighlightedText>free</HighlightedText>
            </>
        ),
    },
};

export const LoginSignupForm = ({
    openWith = OpenWithTypes.SIGNUP,
    signUpSource,
    onSuccess,
    trackingProperties = {} as BaseTrackingProperties,
    allowSocialLogin = true,
}: LoginSignupFormProps) => {
    const { setGlobalState } = useGlobalState();
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const [currentActionType, setActionType] = useState<OpenWithTypes>(openWith);
    const [showLoginForm, setShowLoginFormState] = useState(openWith === OpenWithTypes.LOGIN);
    const [showSignupForm, setShowSignupFormState] = useState(openWith === OpenWithTypes.SIGNUP);
    const [showOTPForm, setShowOTPForm] = useState(openWith === OpenWithTypes.OTP);
    const [email, setEmail] = useState('');
    const [loginAttemptId, setLoginAttemptId] = useState('');
    const updateActionType = (actionType: OpenWithTypes) => setActionType(actionType);
    const onLoginSignUpSuccess = (loginAttemptId: string, email: string) => {
        showOTPHandler();
        setEmail(email);
        setLoginAttemptId(loginAttemptId);
    };
    const showSignUpHandler = () => {
        updateActionType(OpenWithTypes.SIGNUP);
        setShowSignupFormState(true);
        setShowLoginFormState(false);
        setShowOTPForm(false);
    };
    const showLoginHandler = () => {
        updateActionType(OpenWithTypes.LOGIN);
        setShowLoginFormState(true);
        setShowOTPForm(false);
        setShowSignupFormState(false);
    };
    const showOTPHandler = () => {
        updateActionType(OpenWithTypes.OTP);
        setShowOTPForm(true);
        setShowLoginFormState(false);
        setShowSignupFormState(false);
    };
    const heading = FORM_TITLE_MAP[currentActionType]?.title;

    return (
        <div className="flex flex-col justify-center gap-y-6">
            {heading && (
                <Heading
                    importance={4}
                    className="text-3xl mb-4 text-center"
                >
                    {heading}
                </Heading>
            )}

            <div className="flex flex-col gap-y-10">
                {showSignupForm && (
                    <SignupContent
                        signUpSource={signUpSource}
                        onSuccess={onLoginSignUpSuccess}
                        showLoginHandler={showLoginHandler}
                        trackingProperties={trackingProperties}
                    />
                )}

                {showLoginForm && (
                    <LoginContent
                        onSuccess={onLoginSignUpSuccess}
                        showSignUpHandler={showSignUpHandler}
                        trackingProperties={trackingProperties}
                    />
                )}

                {showOTPForm && (
                    <OTPContent
                        email={email}
                        actionType={currentActionType}
                        loginAttemptId={loginAttemptId}
                        onLoginSuccess={onSuccess}
                        submitButtonCopy="Submit"
                        showLoginHandler={showLoginHandler}
                        trackingProperties={trackingProperties}
                    />
                )}
                {allowSocialLogin && (showSignupForm || showLoginForm) && (
                    <div className="flex flex-col gap-y-8">
                        <Paragraph className="text-center relative before:absolute before:h-[1px] before:w-[35%] before:border before:border-analyst-lavender-medium before:top-1/2 after:right-0 before:-translate-y-1/2 after:absolute after:h-[1px] after:w-[35%] after:border after:border-analyst-lavender-medium after:top-1/2 before:left-0 after:-translate-y-1/2 mb-0">
                            or {showLoginForm ? 'login with' : 'sign up with'}
                        </Paragraph>
                        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                            <div className="rounded-full overflow-hidden border-2 border-analyst-gray bg-white hover:border-thematic-purple transition-colors">
                                <GoogleLogin
                                    onSuccess={async (credentialResponse) => {
                                        try {
                                            setGlobalState((prev) => {
                                                return {
                                                    ...prev,
                                                    currentUserLoading: true,
                                                };
                                            });
                                            const idToken = credentialResponse.credential;
                                            const response = await fetch(API_ENDPOINT + '/v1/auth/google/callback', {
                                                body: JSON.stringify({ token: idToken }),
                                                credentials: 'include',
                                                headers: { 'Content-Type': 'application/json' },
                                                method: 'POST',
                                            });
                                            if (!response.ok) {
                                                throw new Error('Failed to authenticate with Google');
                                            }
                                            const data = await response.json();
                                            const { currentUser } = await GQL_CLIENT.request(CURRENT_USER_QUERY);

                                            setGlobalState((prev) => {
                                                return {
                                                    ...prev,
                                                    currentUser,
                                                    currentUserLoading: false,
                                                };
                                            });
                                            toast.success("You're now signed in.");
                                            onSuccess?.(data.user);
                                            trackManualEvent({
                                                eventType: showSignupForm
                                                    ? eventTypes.GOOGLE_SSO_SIGNUP_SUCCESS
                                                    : eventTypes.GOOGLE_SSO_LOGIN_SUCCESS,
                                                trackingProperties: {
                                                    ...trackingProperties,
                                                    additionalTrackingProperties: {
                                                        ...trackingProperties.additionalTrackingProperties,
                                                        email: currentUser.email,
                                                        name: currentUser.name,
                                                        userId: currentUser.id,
                                                    },
                                                    category: categories.LOGIN_SIGNUP,
                                                    component: showLoginForm
                                                        ? components.LOGIN_FORM
                                                        : components.SIGNUP_FORM,
                                                },
                                            });
                                        } catch (error) {
                                            // handle error
                                            toast.error('Failed to authenticate with Google');
                                            trackManualEvent({
                                                eventType: eventTypes.GOOGLE_SSO_ERROR,
                                                trackingProperties: {
                                                    ...trackingProperties,
                                                    category: categories.LOGIN_SIGNUP,
                                                    component: showLoginForm
                                                        ? components.LOGIN_FORM
                                                        : components.SIGNUP_FORM,
                                                    errorType: 'sso',
                                                    message: error,
                                                } as ErrorTrackingProperties,
                                            });
                                        }
                                    }}
                                    size="large"
                                    text={(showSignupForm && 'signup_with') || 'continue_with'}
                                />
                            </div>
                        </GoogleOAuthProvider>
                    </div>
                )}
            </div>
        </div>
    );
};
