'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { OpenWithTypes } from '@/components/forms/login-signup-form';
import { useGlobalState } from '@/components/global/global-state';
import { LoginSignupModal } from '@/components/modals/login-signup-modal';
// import { constructTeamPageUrl } from '@/helpers/organization';
import {
    AVAILABLE_PATHNAMES_FOR_RESEARCH,
    LOGGED_IN_REDIRECT_PATHNAMES,
    LOGIN_PROTECTED_PATHNAMES,
    PRODUCT_PATHNAMES,
    REDIRECT_QUERY_PARAM,
} from '@/lib/constants/general';
// import { TeamViews } from '@/lib/constants/team';
import { CurrentUserType } from '@/queries/current-user';
import { Nullable } from '@/types/nullable';

export enum SignUpSource {
    DEFAULT = 'default',
    EVAL_LIMIT_REACHED = 'eval_limit_reacted',
    RESEARCH_PROMPT = 'research_prompt',
    LOGGED_OUT_MODULE = 'logged_out_module',
    ADD_TO_RESEARCH_CTA = 'add_to_research_cta',
}

export const SignUpSourceCopyMap: {
    [key in SignUpSource]?: React.ReactNode;
} = {
    [SignUpSource.DEFAULT]: null,
    [SignUpSource.EVAL_LIMIT_REACHED]: (
        <>
            You&apos;ve reached the maximum number of company evaluations. To get access to more evaluations, sign up
            for a <strong className="tracking-wide text-thematic-purple">free</strong> Thematic account.
        </>
    ),
    [SignUpSource.RESEARCH_PROMPT]: (
        <>
            To access our research platform, sign up for a{' '}
            <strong className="tracking-wide text-thematic-purple">free</strong> Thematic account.
        </>
    ),
};

const TEAM_AND_PRODUCT_PATHNAMES = [
    ...LOGIN_PROTECTED_PATHNAMES,
    ...PRODUCT_PATHNAMES,
    ...AVAILABLE_PATHNAMES_FOR_RESEARCH,
];

const useLoginRedirect = () => {
    const pathname = usePathname();
    const params = useSearchParams();
    const { push } = useRouter();
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    // const organizationId = currentUser?.primaryOrganization?.id;
    const previousPathname = usePrevious(pathname);
    const previousCurrentUserLoading = usePrevious(currentUserLoading);
    const finishedLoading = previousCurrentUserLoading && !currentUserLoading;
    const isLoginProtected =
        (!!pathname && LOGIN_PROTECTED_PATHNAMES.some((protectedPath) => pathname.startsWith(protectedPath))) || false;
    const isOnProductOrTeamAccountPage = TEAM_AND_PRODUCT_PATHNAMES.some(
        (productPathname) => pathname?.startsWith(productPathname)
    );
    const isOnLoggedInRedirectPage = LOGGED_IN_REDIRECT_PATHNAMES.some(
        (redirectPathname) => pathname?.startsWith(redirectPathname)
    );
    // const teamDashboardPath = (organizationId && constructTeamPageUrl({ organizationId, teamView: TeamViews.DASHBOARD })) || '/app/start';
    const pathChanged = !!previousPathname && !!pathname && previousPathname !== pathname;
    const navigatedToProtectedPageWhileLoggedOut = pathChanged && !currentUser && isLoginProtected;

    // If you log out or already logged out and navigate to a
    // protected page, redirect to login with a redirect URL
    useEffect(() => {
        if (
            pathname &&
            ((finishedLoading && !currentUser && isLoginProtected) || navigatedToProtectedPageWhileLoggedOut)
        ) {
            push(`/login?${REDIRECT_QUERY_PARAM}=${encodeURIComponent(pathname)}`);
        }
    }, [currentUser, finishedLoading, isLoginProtected, navigatedToProtectedPageWhileLoggedOut, pathname, push]);

    // If user logs in and is not on a product or team/account page, then redirect to team dashboard
    // If a user is already logged in and navigates to a login/signup page, then redirect to team dashboard
    useEffect(() => {
        if (
            pathname &&
            ((finishedLoading && !!currentUser) || (!!currentUser && pathChanged && isOnLoggedInRedirectPage))
        ) {
            const redirectPath = params?.get(REDIRECT_QUERY_PARAM);

            redirectPath && push(redirectPath);
        }
    }, [
        currentUser,
        finishedLoading,
        isOnLoggedInRedirectPage,
        isOnProductOrTeamAccountPage,
        params,
        pathChanged,
        pathname,
        push,
    ]);
};

interface ILoggedInGateContext {
    currentUser?: Nullable<CurrentUserType>;
    closeLoginModal: () => void;
    isLoggedIn: boolean;
    modalType: OpenWithTypes;
    openLoginModal: (
        evt: Nullable<React.MouseEvent<Element, MouseEvent>>,
        signUpSource?: SignUpSource,
        modalType?: OpenWithTypes,
        props?: Record<string, unknown>
    ) => void;
    showLoginModal: boolean;
}

interface LoggedInGateProviderProps {
    children: React.ReactNode;
}

export const LoggedInGateContext = createContext<Nullable<ILoggedInGateContext>>(null);

export const LoggedInGateProvider = ({ children }: LoggedInGateProviderProps) => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const isLoggedIn = !!currentUser && !currentUserLoading;
    const [showLoginModal, setShowLoginModalState] = useState<boolean>(false);
    const [modalType, setModalType] = useState<OpenWithTypes>(OpenWithTypes.SIGNUP);
    const [signUpSource, setSignUpSource] = useState<SignUpSource>(SignUpSource.DEFAULT);
    const [modalProps, setModalProps] = useState({});
    const openLoginModal = (
        evt: Nullable<React.MouseEvent<Element, MouseEvent>>,
        signUpSource: SignUpSource = SignUpSource.DEFAULT,
        modalType: OpenWithTypes = OpenWithTypes.LOGIN,
        props = {}
    ) => {
        evt?.preventDefault();
        setModalType(modalType);
        setShowLoginModalState(true);
        setSignUpSource(signUpSource);
        setModalProps((currentModalProps) => ({ ...currentModalProps, ...props }));
    };
    const closeLoginModal = () => setShowLoginModalState(false);
    const values: ILoggedInGateContext = {
        closeLoginModal,
        currentUser,
        isLoggedIn,
        modalType,
        openLoginModal,
        showLoginModal,
    };

    useLoginRedirect();

    return (
        <LoggedInGateContext.Provider value={values}>
            {children}
            <LoginSignupModal
                isOpen={showLoginModal}
                openWith={modalType}
                closeFn={closeLoginModal}
                signUpSource={signUpSource}
                {...modalProps}
            />
        </LoggedInGateContext.Provider>
    );
};

export const useLoggedInGate = () => {
    const context: Nullable<ILoggedInGateContext> = useContext(LoggedInGateContext);

    if (!context) {
        throw new Error('useLoggedInGate must be used within a LoggedInGateProvider');
    }

    return context;
};
