import { type ExpandableModuleWithHeadingProps } from '@/components/global/expandable-module-with-heading';
import { Instrument } from '@/queries/graphql-types';

export enum EvaluationSectionGroups {
    COMPANY = 'COMPANY',
    COMPARISONS = 'COMPARISONS',
}

// TODO: Repetitive with ModuleTypes in general.ts
export enum CompanyModuleTypes {
    DESCRIPTION = 'companyDescription',
    COMPANY_PERFORMANCE = 'companyPerformance',
    DOCUMENTS = 'companyDocuments',
    SSE = 'companySSE',
    COMPANY_ANALYSIS = 'companyAnalysis',
    METRICS = 'companyMetrics',
    INSIGHTS = 'companyInsights',
    FUNDAMENTALS = 'companyFundamentals',
    EARNINGS_CALLS = 'companyEarningsCalls',
    FILINGS = 'companySecFilings',
    PEERS = 'companyPeers',
    FINANCIALS = 'companyFinancials',
    PEER_FUNDAMENTALS = 'companyPeerFundamentals',
    COMPANY_EVALUATION_ANALYSIS = 'companyEvaluationAnalysis',
    PRICE_HISTORY = 'companyPriceHistory',
    NEWS = 'companyNews',
    VALUATION = 'companyValuation',
}

export enum CompanyInfoSectionGroupKeys {
    COMPANY_ANALYSIS = 'companyAnalysis',
    COMPANY_PERFORMANCE = 'performance',
    DESCRIPTION = 'description',
    METRICS = 'metrics',
    DOCUMENTS = 'documents',
}

export enum EvaluationSectionGroupKeys {
    KEY_TAKEAWAYS = 'keyTakeaways',
    EVALUATION_SUMMARY = 'evaluationSummary',
    PRICE_HISTORY = 'priceHistory',
    FUNDAMENTALS = 'fundamentals',
    FINANCIAL_STATEMENTS = 'financialStatements',
    FILINGS = 'filings',
    EARNINGS_CALL = 'earningsCall',
    NEWS = 'news',
    COMPARATIVE_ANALYSIS = 'comparativeAnalysis',
    PEER_FUNDAMENTALS = 'peerFundamentals',
}

export type BaseCompany = Pick<Instrument, '__typename' | 'companyName' | 'exchange' | 'symbol'> & { id?: string };

export type CompanyModuleProps = Omit<ExpandableModuleWithHeadingProps, 'title' | 'moduleType'>;

export enum CompanyValuations {
    FAIR = 'fair',
    OVER = 'over',
    UNDER = 'under',
}

export type MarketingPartialEvaluation = {
    instrumentId: string;
    companyName: string;
    symbol: string;
    exchange: string;
    valuation: CompanyValuations;
    date: Date;
    peerIds: Array<string>;
    benchmarkIds: Array<string>;
    requestId: string;
};

export type PartialInstrument = Pick<Instrument, 'id' | 'companyName' | 'symbol' | 'exchange' | 'instrumentType'>;

export type MarketingFeaturedEvaluation = MarketingPartialEvaluation & {
    peers: Array<PartialInstrument>;
    benchmarks: Array<PartialInstrument>;
};
